$dropdownClass: '.' + $classPrefix + 'dropdown-button';

#{$dropdownClass} {
	--dropdown-height: #{$inputHeight};
	--dropdown-arrow-size: #{get($icon-sizes, sm)};
	--dropdown-options-height: 200px;
	--dropdown-item-padding: 12px;
	--control-border-color: #7e8299;
	--control-text-color: #7e8299;
	--control-font-weight: 600;
	--floater-coords-left: 0;
	--floater-coords-top: 100%;
	$self: &;
	font-size: 14px;
	position: relative;

	&__control {
		align-items: center;
		border: 1px solid var(--control-border-color);
		border-radius: 6px;
		color: var(--control-text-color);
		cursor: pointer;
		display: flex;
		font-weight: var(--control-font-weight);
		gap: 12px;
		height: var(--dropdown-height);
		justify-content: space-between;
		line-height: 1;
		padding: 12px;

		&-arrow {
			font-size: var(--dropdown-arrow-size);
		}
	}

	&__options {
		background-color: white;
		border: 1px solid #eaedf2;
		border-radius: 6px;
		box-shadow: 4px 4px 34px rgba(0, 0, 0, 0.05);
		display: none;
		left: var(--floater-coords-left);
		max-height: var(--dropdown-options-height);
		overflow-y: auto;
		position: absolute;
		top: var(--floater-coords-top);
		z-index: $z-index-dropdown;
	}

	&__item {
		cursor: pointer;
		padding: var(--dropdown-item-padding);

		&:hover {
			background-color: #d1d3e0;
		}

		&:where(#{$self}-item--active) {
			background-color: $primary;
			color: $white;

			&:hover {
				background-color: $primary-light;
			}
		}
	}

	// States - Opened
	&--open {
		#{$self}__options {
			display: block;
		}
	}

	// States - Active
	&--active {
		#{$self}__control {
			background-color: #f4f7fc;
			border-color: $primary;
			color: $primary;
		}
	}

	&--icon {
		#{$self}__control {
			padding: 1px 6px;
		}
	}
}
