@import '../../abstracts/mixins';
.#{$pn-prefix} {
	$self: &;

	&panel {
		background-color: $tertiaryColor;
		display: inline-block;
		position: absolute;
		box-sizing: border-box;
		padding: 5px;
		z-index: $z-panel;

		.icon-undo,
		.icon-redo {
			font-size: 20px;
			height: 30px;
			width: 25px;
		}
	}

	&commands {
		background-color: $tertiaryColor;
		box-shadow: 0 0 5px $mainDkColor;
		left: 0;
		top: 0;
		width: calc(100% - $leftWidth);
	}

	&options {
		right: $leftWidth;
		top: 0;
	}

	&actions,
	&navigate,
	&title {
		height: $canvasTop;
		padding: 8px 24px;
		top: 0;
	}

	&actions {
		right: $leftWidth;

		.eb-pn-btn {
			padding: 0;
		}

		.eb-actions-dropdown {
			padding: 0;
		}
	}

	&navigate {
		left: 0;

		#{$self}buttons {
			gap: 16px;
		}

		.eb-go-back-button {
			align-items: center;
			border: 1px solid var(--DT-Gray-04, #7e8299);
			border-radius: 4px;
			color: var(--DT-Gray-04, #7e8299);
			display: flex;
			// font-size: get($icon-sizes, sm); // TODO: fix variables
			height: 40px;
			justify-content: center;
			width: 40px;
		}
	}

	&title {
		width: calc(100% - $leftWidth);
		display: flex;
		justify-content: right;
		padding-right: 270px;
		padding-left: 180px;
		overflow: hidden;

		.eb-pn-buttons,
		.eb-input {
			max-width: 450px;
			width: 100%;
		}

		.eb-title-input {
			padding: 0;
			width: 100%;
		}

		.eb-label {
			line-height: 40px; // TODO: add $inputHeight variable
		}

		@include media-breakpoint(1050px) {
			justify-content: center;
			padding-left: 270px;
		}

		@include media-breakpoint(xlarge) {
			justify-content: right;
			padding-left: 180px;
			padding-right: 475px;
		}

		@include media-breakpoint(1600px) {
			justify-content: center;
			padding-left: 475px;
		}
	}

	&devices {
		height: $canvasTop;
		top: 0;
		width: calc(100% - $leftWidth);
	}

	&buttons {
		align-items: center;
		display: flex;
		justify-content: flex-end;
	}

	&btn {
		box-sizing: border-box;
		min-height: 30px;
		min-width: 30px;
		line-height: 21px;
		background-color: transparent;
		border: none;
		font-size: 18px;
		border-radius: 2px;
		padding: 4px;
		position: relative;
		cursor: pointer;

		&:hover {
			color: $secondaryColor;
		}

		&.#{$pn-prefix}active {
			color: $primaryColor;
		}

		svg {
			fill: currentColor;
		}

		&__title {
			display: none;

			@include media-breakpoint(xlarge) {
				display: block;
			}
		}
	}

	&views,
	&main-view,
	&traits-view {
		background-color: #ffffff;
		right: 0;
		width: $leftWidth;

		&-container {
			background-color: #ffffff;
			height: 100%;
			padding: 42px 0 20px;
			right: 0;
			width: $leftWidth;
			overflow: auto;
			box-shadow: 0 0 5px $mainDkColor;

			&:has(.traits-wrapper--active) {
				padding-top: 0;
			}
		}
	}

	&views {
		z-index: $z-views-panel;
	}

	&main-view,
	&traits-view {
		padding: 0;

		.#{$pn-prefix}buttons {
			@extend .#{$app-prefix}tabs__tabs !optional;
			justify-content: space-around;
		}

		.#{$pn-prefix}btn {
			@extend .#{$app-prefix}tabs__tab-item !optional;
		}

		.#{$pn-prefix}active {
			@extend .#{$app-prefix}tabs__tab-item--active !optional;
		}
	}

	&main-view {
		z-index: $z-main-view-panel;
	}

	&traits-view {
		height: 100%;
		overflow-y: auto;
		z-index: $z-traits-view-panel;
	}

	&save-changes {
		bottom: 0;
		font-size: inherit;
		height: $canvasBottom;
		padding: 10px 24px;
		width: calc(100% - $leftWidth);

		.#{$pn-prefix}buttons {
			display: grid;
			grid-template-areas:
				'icon title button'
				'icon subtitle button';
			grid-template-columns: auto 1fr auto;
		}
	}

	&context {
		align-items: center;
		background-color: #7e8299;
		bottom: 100%;
		color: #ffffff;
		display: flex;
		height: 50px;
		padding: 10px;
		right: 0;
		text-align: left;
		width: $leftWidth;

		.#{$pn-prefix}buttons {
			justify-content: start;
		}

		.context__icon {
			align-items: center;
			border: 1px solid #ffffff;
			border-radius: 2.5px;
			color: #ffffff;
			display: flex;
			// font-size: get($icon-sizes, sm); // TODO: fix variables
			height: 24px;
			justify-content: center;
			width: 24px;

			&--hidden {
				display: none;
			}
		}

		.context__title {
			cursor: unset;

			&:hover {
				color: inherit;
			}
		}
	}

	&zoom {
		background-color: transparent;
		bottom: 8px;
		left: 0;

		&:where(.#{$app-prefix}editor--has-changes &) {
			bottom: $canvasTop + 8px;
		}
	}
}

@mixin reset-pn-btn-style {
	height: auto;
	min-height: unset;
	padding: 0;
}

.general-style-pn {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	&__footer {
		display: flex;
		justify-content: flex-end;
	}
}

.save-changes {
	&__icon {
		@include reset-pn-btn-style();
		grid-area: icon;
	}

	&__title {
		@include reset-pn-btn-style();
		font-size: 14px;
		font-weight: 600;
		grid-area: title;
	}

	&__subtitle {
		@include reset-pn-btn-style();
		font-size: 12px;
		grid-area: subtitle;
	}

	&__button:is(&__button) {
		font-size: inherit;
		@extend .#{$app-prefix}btn;
		@extend .#{$app-prefix}btn--primary !optional;
		grid-area: button;
	}
}

.zoom-slider {
	height: 200px;
}
