$dropdownClass: '.' + $classPrefix + 'floater';

#{$dropdownClass} {
	position: absolute;
	will-change: left, top;
	z-index: $z-index-floater;

	&:not(&--active) {
		display: none;
	}

	&--fixed {
		position: fixed;
	}

	&__trigger {
		cursor: pointer;
	}
}
