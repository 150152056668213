$sliderClass: '.' + $classPrefix + 'slider';

#{$sliderClass} {
	--gap: 2px;
	background-color: transparent;
	box-shadow: none;
	font-size: 10px;

	.noUi-origin {
		height: 100%;
		width: 100%;
	}

	&--vertical {
		height: 100%;
		width: 5.5px;

		.noUi-base {
			width: 0.4em;
		}

		.noUi-connects {
			width: 4px;
		}

		.noUi-handle {
			top: 100%;
			left: 50%;
			translate: -50% -50%;
		}
	}

	&--horizontal {
		border: 0;
		height: auto;
		width: calc(100% - var(--gap) * 2);

		.noUi-base {
			height: 0.4em;
		}

		.noUi-connects {
			height: 4px;
		}

		.noUi-handle {
			top: 50%;
			left: 100%;
			transform: translate(-50%, -50%);
		}
	}

	.noUi-connects {
		background: #f4f7fc;
	}

	.noUi-handle {
		border-radius: 50%;
		box-shadow: none;
		height: 1.2em;
		width: 1.2em;

		&:before,
		&:after {
			content: none;
		}
	}

	.noUi-connect {
		background: $primary;
	}

	.noUi-pips-horizontal {
		height: 0.4em;
		padding: 1em 0 2em;
		position: static;
		top: 0.4em;
	}

	.noUi-marker-horizontal.noUi-marker-large {
		height: 0.5em;
	}

	&--tooltip-handling {
		.noUi-handle:not(.noUi-active) {
			.noUi-tooltip {
				display: none;
			}
		}
	}

	&[disabled] {
		opacity: 0.4;

		.noUi-handle {
			display: none;
		}
	}
}
