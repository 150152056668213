$buttonClass: '.' + $classPrefix + 'btn';

#{$buttonClass} {
	--button-size: $inputHeight;

	$self: &;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 6px;
	font-size: 1rem;
	cursor: pointer;
	font-weight: 600;
	min-height: var(--button-size);
	padding: 8px 12px;

	&:disabled {
		cursor: not-allowed;
		opacity: 0.3;
	}

	&--primary {
		background: $primary;
		color: $white;

		&-outline {
			background: transparent;
			border-color: currentColor;
			color: $primary;
		}

		&:hover {
			background: darken($primary, 10%);
		}
	}

	&--gray {
		background: $dt-gray-02;
		color: $dt-gray-03;

		&-outline {
			background: transparent;
			border-color: currentColor;
			color: $dt-gray-03;
		}

		&:hover {
			background: darken($dt-gray-02, 10%);
		}
	}

	&--danger {
		background: $danger;
		color: $white;

		&-outline {
			background: transparent;
			border-color: currentColor;
			color: $danger;

			&:hover {
				background: lighten($danger, 45%);
			}
		}
	}

	&--toggle {
		background: #ffffff;
		border-color: #eaedf2;
		color: #7e8299;

		&#{$self}--active,
		&:active {
			background-color: #f4f7fc;
			border-color: currentColor;
			color: $primary;
		}

		&:hover {
			background-color: #f4f7fc;
		}
	}

	&--link {
		background: transparent;
		border-color: transparent;
		color: #7e8299;

		&#{$self}--active,
		&:active {
			background-color: #f4f7fc;
			color: $primary;
		}

		&:hover {
			background-color: #eaedf2;
		}
	}

	&--icon {
		display: inline-flex;
		gap: 0.5rem;
	}

	&--sm {
		--button-size: 30px;
	}

	&--square {
		align-items: center;
		display: inline-flex;
		justify-content: center;
		height: var(--button-size);
		padding: 0;
		width: var(--button-size);
	}

	&--circle {
		align-items: center;
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		height: var(--button-size);
		padding: 0;
		width: var(--button-size);
	}
}
