.rich-editor-dropdown {
	$self: &;
	cursor: pointer;

	&--open {
		#{$self}__options {
			display: block;
		}
	}

	&__control {
		align-items: center;
		border: 1px solid #eaedf2;
		border-radius: 6px;
		display: flex;
		justify-content: space-between;
		padding: 4px 8px 4px 4px;
	}

	&__options {
		display: none;
		border-color: #eaedf2;
		border-radius: 0 0 6px 6px;
		border-style: solid;
		border-width: 0 1px 1px;
	}

	&__item {
		align-items: center;
		display: flex;
		gap: 4px;

		&--active {
			color: blue;
		}
	}

	&__item-icon {
		line-height: 1;
	}
}
