.#{$icon-class} {
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	line-height: 1;
}

// Sizes modifier classes

@each $name, $value in $icon-sizes {
	.#{$icon-class}--#{$name} {
		font-size: $value;
	}
}
