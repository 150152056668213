@use 'sass:math';
@use 'variables' as *;

:root {
	--alwan-pattern: #{$transparency-pattern-svg};
}

.alwan {
	width: 100%;
	max-width: $alwan-max-width;
	background: var(--alwan-bg, $alwan-background);
	border: 1px solid var(--alwan-outer-bc, $alwan-outer-border-color);
	display: flex;
	flex-direction: column;
	gap: 12px;

	& * {
		box-sizing: border-box;
	}

	@include dark-theme();

	&:not(.alwan--open) {
		visibility: hidden;
	}

	&:not(.alwan--popup, .alwan--open) {
		overflow: hidden;
		height: 0;
		border: 0;
	}

	&--popup {
		padding: 12px;
		position: fixed;
		top: 4px;
		left: 0;
		z-index: $alwan-popup-z-index;
		border: 0;
		border-radius: $alwan-border-radius;
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
			0px 8px 10px 1px rgba(0, 0, 0, 0.14),
			0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	}

	&__input {
		display: block;
		width: 100%;
		padding: $input-padding-y $input-padding-x;
		text-align: center;
		color: var(--alwan-fg, $input-color);
		font-size: $input-font-size;
		font-family: $input-font-family;
		border: 1px solid var(--alwan-bc, $alwan-border-color);
		margin-bottom: $input-margin-bottom;
		background-color: var(--alwan-input-bg, $input-background);
		line-height: $input-line-height;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		&:focus {
			outline: 0;
			border-color: $alwan-focus-color;

			+ span {
				color: $alwan-focus-color;
			}
		}

		&:hover {
			border-color: var(
				--alwan-input-hover-bc,
				$input-border-color-hover
			);
		}
	}

	&__button {
		display: inline-block;
		background: transparent;
		color: var(--alwan-fg, $alwan-foreground);
		border: 1px solid transparent;
		cursor: pointer;
		padding: $button-padding-y $button-padding-x;
		transition: background-color 0.2s ease-in-out;
		outline: 0;

		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}

		&:hover {
			background-color: var(
				--alwan-button-hover,
				$button-hover-background
			);
		}

		&:focus-visible {
			border-color: $alwan-focus-color;
		}

		svg {
			fill: currentColor;
			pointer-events: none;
			vertical-align: middle;
		}
	}

	// Container.
	&__container {
		position: relative;
		display: flex;
		align-items: center;
		gap: 4px;
	}

	// Palette.
	&__palette {
		overflow: hidden;
		height: $palette-height;
		outline: 0;
		touch-action: none;
		user-select: none;
		cursor: crosshair;
		background: linear-gradient(to bottom, transparent, #000),
			linear-gradient(to right, #fff, hsl(var(--alwan-h, 0), 100%, 50%));

		&:focus .alwan__marker {
			box-shadow: 0 0 1px 2px $alwan-focus-color;
		}
	}

	&__marker {
		position: relative;
		top: math.div($marker-height, 2) * -1;
		left: math.div($marker-width, 2) * -1;
		width: $marker-width;
		height: $marker-height;
		border-radius: 100%;
		background: transparent;
		border: $marker-border;
		box-shadow: $box-shadow;
		cursor: move;
	}

	&__preview {
		width: $preview-area-width;
		height: $preview-area-height;
		order: 1;
		border: $preview-area-border-width solid
			var(--alwan-bc, $alwan-border-color);
		border-radius: 6px;
		flex-shrink: 0;

		& .alwan__copy-button {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			color: #fff;
			opacity: 0;
			border-radius: 0;
			margin: 0;

			&:focus,
			&:hover {
				opacity: 1;
				z-index: 100;
				background-color: rgba(0, 0, 0, 0.6);
			}
		}
	}

	&__sliders {
		width: 100%;
	}

	&__slider {
		display: block;
		width: 100%;
		background-color: transparent;
		margin: 0;
		padding: 0;
		cursor: pointer;
		outline: 0;
		-webkit-appearance: none;
		appearance: none;
		--alwan-alpha: linear-gradient(90deg, transparent, var(--alwan-rgb)),
			var(--alwan-pattern);
		--alwan-hue: linear-gradient(
			-90deg,
			#ff0000,
			#ffff00,
			#00ff00,
			#00ffff,
			#0000ff,
			#ff00ff,
			#ff0000
		);

		&:active {
			cursor: ew-resize;
		}

		&--alpha {
			margin-top: $alpha-slider-margin-top;
		}

		// Webkit slider.
		&::-webkit-slider-runnable-track {
			-webkit-appearance: none;
			appearance: none;
			height: $slider-track-height;
			border: 0;

			&:active {
				cursor: ew-resize;
			}
		}

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			height: $slider-thumb-height;
			width: $slider-thumb-width;
			margin-top: -(math.div($slider-thumb-height - $slider-track-height, 2));
			background-color: var(--alwan-thumb-bg, $slider-thumb-background);
			border: 1px solid var(--alwan-thumb-bc, $slider-thumb-border-color);
			border-radius: $slider-thumb-border-radius;
			box-shadow: $box-shadow;
			cursor: ew-resize;
		}

		&:focus::-webkit-slider-thumb {
			border-color: $alwan-focus-color;
		}

		// Firefox slider.
		&::-moz-range-track {
			height: $slider-track-height;
			border: 0;
		}

		&::-moz-range-thumb {
			box-sizing: border-box;
			width: $slider-thumb-width;
			height: $slider-thumb-height;
			padding: 0;
			background-color: var(--alwan-thumb-bg, $slider-thumb-background);
			border: 1px solid var(--alwan-thumb-bc, $slider-thumb-border-color);
			border-radius: $slider-thumb-border-radius;
			box-shadow: $box-shadow;
			cursor: ew-resize;
		}

		&:focus::-moz-range-thumb {
			border-color: $alwan-focus-color;
		}

		&--hue {
			&,
			&:focus {
				// Webkit slider
				&::-webkit-slider-runnable-track {
					background: var(--alwan-hue);
				}

				// Firefox slider
				&::-moz-range-track {
					background: var(--alwan-hue);
				}
			}
		}

		&--alpha {
			&,
			&:focus {
				// Webkit slider
				&::-webkit-slider-runnable-track {
					background: var(--alwan-alpha);
				}

				// Firefox slider
				&::-moz-range-track {
					background: var(--alwan-alpha);
				}
			}
		}
	}

	&__inputs {
		display: flex;
		width: 100%;

		label {
			width: 100%;
			text-align: center;
			margin-right: $label-margin-right;
			text-transform: uppercase;
			color: var(--alwan-label, $label-color);
			cursor: pointer;
			font-family: $label-font-family;
			font-size: $label-font-size;
			-webkit-user-select: none;
			-moz-user-select: none;
			user-select: none;
		}
	}

	&__swatches {
		display: flex;
		flex-wrap: wrap;
		gap: 6px;
		background-color: var(
			--alwan-swatches-bg,
			$swatches-container-background
		);
		max-height: $swatches-container-max-height;
		min-height: calc($swatch-button-height + 2px);
		overflow-y: auto;
	}

	&__swatch {
		width: $swatch-button-width;
		height: $swatch-button-height;
		border: 0;
		border-radius: $swatch-button-border-radius;
		min-height: unset;

		&:hover {
			transform: scale(1.1);
		}
	}

	&__preset-button {
		width: $preset-button-width;
		height: $preset-button-height;
		border-radius: $preset-button-border-radius;
	}

	&__swatch:focus,
	&__preset-button:focus {
		outline: 1px dashed var(--alwan-color);
		outline-offset: 1px;
	}

	&__backdrop {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999999999;
		cursor: move;
	}
	&__swatch,
	&__preview,
	&__preset-button {
		position: relative;
		background: var(--alwan-pattern);
		background-clip: padding-box;
		overflow: hidden;
		padding: 0;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: var(--alwan-color);
		}
	}

	&__toggle-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0;
		height: $swatches-toggle-button-height;
		border-top: 1px solid var(--alwan-bc, $alwan-border-color);
		overflow: hidden;
	}

	&--collapse {
		&.alwan__swatches {
			display: none;
		}

		& + .alwan__toggle-button svg {
			transform: rotate(180deg);
		}
	}
}
