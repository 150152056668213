$textEditorClass: '.' + $classPrefix + 'prosetyper';

:root {
	--text-editor-inputs-height: 25px;
}

#{$textEditorClass} {
	background: var(--DT-White, #fff);
	border-radius: 6px;
	border: 1px solid var(--DT-Gray-02, #eaedf2);

	&-frame {
		border: none;
		width: 100%;
	}

	&-separator {
		height: 1px;
		width: 100%;
		background-color: var(--DT-Gray-02, #eaedf2);

		&--vertical {
			height: 100%;
			width: 1px;
		}
	}

	&-dropdown {
		--dropdown-control-gap: 0;
		--dropdown-control-padding: 3.5px;
		&-item {
			&__label {
				// TODO: FIX
				&:where(.#{$classPrefix}dropdown__control *) {
					display: none;
				}
			}
		}
	}

	&-toolbar {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		gap: 4px;
		padding: 8px;

		&__button {
			align-items: center;
			background-color: var(--DT-White, #ffffff);
			border-radius: 4px;
			border: 1px solid var(--DT-Gray-02, #eaedf2);
			color: #7e8299;
			cursor: pointer;
			display: inline-flex;
			height: var(--text-editor-inputs-height);
			padding: 1px 6px;

			&:hover {
				background-color: $primary;
				border-color: currentColor;
				color: $white;
			}

			&--active {
				background-color: #f4f7fc;
				border-color: currentColor;
				color: $primary;
				font-weight: 600;

				&:hover {
					background-color: #f4f7fc;
					border-color: currentColor;
					color: $primary-light;
				}
			}
		}

		&__icon {
			color: #7e8299;
		}

		&__link-editor {
			background-color: var(--DT-White, #ffffff);
			box-shadow: 0px 0px 14px -12px rgba(0, 0, 0, 0.75);
			display: flex;
			padding: 8px;
			gap: 12px;
		}

		&__dropdown {
			--dropdown-height: var(--text-editor-inputs-height);
			--dropdown-arrow-size: get($icon-sizes, xs);
			--control-border-color: var(--DT-Gray-02, #eaedf2);
			--control-text-color: var(--DT-Gray-02, #7e8299);
			--control-font-weight: 400;
			font-size: 12px;

			&-item {
				align-items: center;
				display: flex;
				gap: 8px;
			}
		}

		&__color-picker {
			--swatch-size: 18px;
			--picker-input-height: var(--text-editor-inputs-height);
		}
	}

	&-input-actions {
		&__input {
			--input-height: var(--text-editor-inputs-height);
		}

		&__button {
			background-color: transparent;
			border: none;
			cursor: pointer;

			&:hover {
				color: $primary;
			}
		}
	}
}
