.#{$rte-prefix} {
	&toolbar {
		@extend .#{$app-prefix}no-user-select;

		position: absolute;
		z-index: $z-rte-toolbar;
	}

	&toolbar-ui {
		border: 1px solid $mainDkColor;
		border-radius: 3px;
	}

	&actionbar {
		display: flex;
	}

	&action {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		width: 25px;
		border-right: 1px solid $mainDkColor;
		text-align: center;
		cursor: pointer;
		outline: none;

		&:last-child {
			border-right: none;
		}

		&:hover {
			background-color: $mainLhColor;
		}
	}

	&active {
		background-color: $mainLhColor;
	}
	&disabled {
		color: $mainLhColor;
		cursor: not-allowed;
		&:hover {
			background-color: unset;
		}
	}
}
