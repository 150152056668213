$modalClass: '.' + $classPrefix + 'modal';

#{$modalClass} {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: $z-index-floater;

	&--open {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-dialog {
		position: absolute;
		background: white;
		border-radius: 4px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		border: 1px solid #ccc;
	}
}
