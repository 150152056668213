$popupClass: '.' + $classPrefix + 'popup';

#{$popupClass} {
	--popup-width: 300px;
	--popup-min-height: 200px;

	background-color: $white;
	border: 1px solid var(--dt-gray-02, #eaedf2);
	border-radius: 6px;
	box-shadow: 4px 4px 34px 0px #0000000d;
	display: none;
	min-height: var(--popup-min-height);
	padding: 10px;
	position: fixed;
	width: var(--popup-width);
	z-index: $z-index-floater;

	&--center {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&__header {
		display: flex;
		justify-content: space-between;
	}

	&--open {
		display: block;
	}
}
