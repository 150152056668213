.#{$app-prefix}blocks-c {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.#{$app-prefix}block-categories {
	display: flex;
	flex-direction: column;
}

.#{$app-prefix}block-category {
	width: 100%;

	&.#{$app-prefix}open {
		@extend .#{$app-prefix}category-open;
	}

	.#{$app-prefix}title {
		@extend .#{$app-prefix}category-title;
	}

	.#{$app-prefix}caret-icon {
		margin-right: 5px;
	}
}

.#{$app-prefix}block-category-accordion {
	&.#{$app-prefix}accordion--open {
		.#{$app-prefix}accordion__content {
			display: grid;
			gap: 8px;
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

.#{$app-prefix}block {
	@include user-select(none);

	&:where(:not(.#{$app-prefix}block--custom)) {
		margin: 10px 2.5% 5px;
		width: 45%;
	}

	min-width: 45px;
	padding: 1em;
	box-sizing: border-box;
	min-height: 90px;
	cursor: all-scroll;
	font-size: 11px;
	font-weight: lighter;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: 1px solid #eaedf2;
	border-radius: 6px;
	transition: all 0.2s ease 0s;
	transition-property: box-shadow, color;

	//   &:hover {
	//     box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
	//   }

	svg {
		fill: currentColor;
	}

	&__media {
		margin-bottom: 10px;
		pointer-events: none;
	}
}

.#{$app-prefix}block-svg {
	width: 54px;
	fill: currentColor;
}

.#{$app-prefix}block-svg-path {
	fill: currentColor;
}

.#{$app-prefix}block.fa {
	font-size: 2em;
	line-height: 2em;
	padding: 11px;
}

.#{$app-prefix}block-label {
	line-height: normal;
	font-size: $fontSizeS;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	pointer-events: none;
}

.#{$app-prefix}block.#{$app-prefix}bdrag {
	width: auto;
	padding: 0;
}
