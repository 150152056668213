$radioButtonGroupClass: '.' + $classPrefix + 'radio-btn-group';

#{$radioButtonGroupClass} {
	$self: &;
	display: flex;
	text-align: center;

	&-item {
		background: white;
		border-color: #eaedf2;
		border-style: solid;
		border-width: 1px 0 1px 1px;
		cursor: pointer;
		flex-grow: 1;
		font-weight: 500;
		font-size: 12px;
		padding: 14px 16px;

		&:last-child {
			border-radius: 0px 6px 6px 0px;
			border-width: 1px;
		}

		&:first-child {
			border-radius: 6px 0px 0px 6px;
		}

		&--active {
			background-color: #f4f7fc;
			border-width: 1px;
			color: $primary;
			font-weight: 600;

			&:where(&-border) {
				border-color: currentColor;
			}
		}

		&--active + & {
			border-left: 0;
		}
	}
}
