$dropdownClass: '.' + $classPrefix + 'dropdown';

#{$dropdownClass} {
	--dropdown-height: #{$inputHeight};
	--dropdown-arrow-size: get($icon-sizes, sm);
	--dropdown-options-height: 200px;
	--dropdown-item-padding: 12px;
	--dropdown-control-border-color: #eaedf2;
	--dropdown-control-text-color: inherit;
	--dropdown-control-font-weight: inherit;
	--dropdown-control-gap: 12px;
	--dropdown-control-padding: 0 12px;
	--floater-coords-left: 0;
	--floater-coords-top: 100%;
	$self: &;
	font-size: 14px;
	position: relative;

	&__control {
		align-items: center;
		border: 1px solid var(--dropdown-control-border-color);
		border-radius: 6px;
		color: var(--dropdown-control-text-color);
		cursor: pointer;
		display: flex;
		font-weight: var(--dropdown-control-font-weight);
		gap: var(--dropdown-control-gap);
		height: var(--dropdown-height);
		justify-content: space-between;
		line-height: 1;
		padding: var(--dropdown-control-padding);

		&-content {
			align-items: center;
			display: flex;
		}

		&-arrow {
			font-size: var(--dropdown-arrow-size);
		}
	}

	&__options {
		background-color: white;
		border: 1px solid #eaedf2;
		border-radius: 6px;
		box-shadow: 4px 4px 34px rgba(0, 0, 0, 0.05);
		display: none;
		left: var(--floater-coords-left);
		max-height: var(--dropdown-options-height);
		overflow-y: auto;
		position: absolute;
		top: var(--floater-coords-top);
		z-index: $z-index-dropdown;
	}

	&__item {
		cursor: pointer;
		padding: var(--dropdown-item-padding);

		&:hover {
			background-color: #d1d3e0;
		}
	}

	// States - Opened
	&--open {
		#{$self}__options {
			display: block;
		}
	}

	// Modifiers - sm
	&--sm {
		--dropdown-height: 24px;
		--dropdown-arrow-size: get($icon-sizes, sm);
		--dropdown-item-padding: 8px;
		--dropdown-control-gap: 8px;
		--dropdown-control-padding: 0 8px;
		font-size: 10px;
	}
}
