@import 'alwan';

$pickerClass: '.' + $classPrefix + 'color-picker-input';

#{$pickerClass} {
	--swatch-size: 24px;
	--picker-input-height: #{$inputHeight};
	$self: &;
	position: relative;

	&__input:is(input) {
		background: var(--white, #fff);
		border: 1px solid var(--dt-gray-02, #eaedf2);
		border-radius: 6px;
		height: var(--picker-input-height);
		padding: calc((var(--picker-input-height) - var(--swatch-size)) / 2) 8px
			calc((var(--picker-input-height) - var(--swatch-size)) / 2) 12px;
		width: 100%;
	}

	&__reset-btn {
		align-items: center;
		color: #eaedf2;
		cursor: pointer;
		display: flex;
		position: absolute;
		right: 40px;
		top: 50%;
		transform: translateY(-50%);

		&:hover {
			color: $primary;
		}
	}

	&__swatch {
		background-color: var(--white, #fff);
		border: 1px solid var(--dt-gray-02, #eaedf2);
		border-radius: 6px;
		height: var(--swatch-size);
		overflow: hidden;
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		width: var(--swatch-size);
		z-index: 1;

		&::before {
			--mesh-size: 25%;
			background: repeating-conic-gradient(
					#808080 0% 25%,
					transparent 0% 50%
				)
				50% / calc(var(--mesh-size) * 2) calc(var(--mesh-size) * 2);
			// clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
			content: '';
			display: inline-block;
			height: 100%;
			left: 0;
			position: absolute;
			width: 100%;
		}

		&::after {
			background-color: var(--alwan-color, transparent);
			content: '';
			display: inline-block;
			height: 100%;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}

	&--transparent {
		#{$self}__swatch {
			&::before {
				background: red;
				clip-path: polygon(
					calc(100% - 1px) 0,
					100% 1px,
					1px 100%,
					0 calc(100% - 1px),
					calc(100% - 1px) 0
				);
			}
		}
	}

	&--no-input {
		background: var(--white, #fff);
		border: 1px solid var(--dt-gray-02, #eaedf2);
		border-radius: 6px;
		cursor: pointer;
		height: var(--picker-input-height);
		padding: calc((var(--picker-input-height) - var(--swatch-size)) / 2);

		#{$self}__swatch {
			left: 0;
			position: relative;
			top: 0;
			transform: none;
		}
	}
}
