$inputClass: '.' + $classPrefix + 'input';

#{$inputClass} {
	--input-height: #{$inputHeight};

	background: #ffffff;
	border: 1px solid #eaedf2;
	border-radius: 6px;
	font-size: 14px;
	height: var(--input-height);
	padding: 12px;

	&:focus-visible {
		border-color: $primary;
	}
}

#{$inputClass}:where([type='range']) {
	height: auto;
	border: 0;
}
