$accordionClass: '.' + $classPrefix + 'accordion';

#{$accordionClass} {
	$self: &;

	width: 100%;

	&__control {
		align-items: center;
		background-color: $primary;
		color: #ffffff;
		cursor: pointer;
		display: flex;
		font-weight: 500;
		justify-content: space-between;
		padding: 8px 14px 8px 10px;

		&-inner {
			flex-grow: 1;
		}
	}

	&__content {
		background-color: #ffffff;
		display: none;
		padding: 16px 10px;
	}

	&__arrow {
		font-size: get($icon-sizes, sm);
	}

	&--open {
		> #{$self}__content {
			display: block;
		}
	}

	// Variations
	// Dimension variations
	&--extend {
		> #{$self}__control {
			padding-right: 24px;
		}
	}

	// Color variations
	&--grey {
		> #{$self}__control {
			background-color: #f4f7fc;
			color: #7e8299;
		}

		#{$self}__arrow {
			color: #b5b4c3;
		}
	}

	&--grey-outline {
		> #{$self}__control {
			background-color: transparent;
			border-bottom: 0.5px solid #b5b4c3;
			color: #7e8299;
		}

		#{$self}__arrow {
			color: #b5b4c3;
		}
	}
}
