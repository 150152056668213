.rich-editor {
	background-color: #ffffff;
	border: 1px solid #eaedf2;
	border-radius: 6px;
	padding: 8px;
	position: relative;
	width: 100%;

	&__editor {
		padding: 8px;

		&:focus-visible {
			outline: 0;
		}
	}

	&__placeholder {
		color: #7e8299;
	}

	&__toolbar {
	}

	&__action {
		margin-left: 4px;
	}

	&-action {
		background: #ffffff;
		border: 1px solid #eaedf2;
		border-radius: 4px;
		color: #7e8299;
		cursor: pointer;
		height: 25px;
		text-align: center;

		&--square {
			width: 25px;
		}

		&--active {
			border-color: currentColor;
			color: #456cc0;
		}
	}

	&__dropdown {
		&--hidden {
			display: none;
		}
	}

	&__separator {
		height: 1px;
		background-color: #eaedf2;
		margin-left: -8px;
		margin-top: 8px;
		width: calc(100% + 16px);
	}
}
