$linkedInputsClass: '.' + $classPrefix + 'linked-inputs';

#{$linkedInputsClass} {
	$self: &;
	align-items: flex-end;
	display: flex;

	&__items {
		align-items: flex-end;
		display: inline-flex;
	}

	&__item {
		&:first-child #{self}__input {
			border-radius: 6px 0 0 6px;
		}
	}

	&__label {
		color: var(--DT-Gray-04, #7e8299);
		font-size: 10px;
		font-weight: 500;
		line-height: 140%;
	}

	&__input {
		border-right-width: 0;
		border-radius: 0;
	}

	&__link {
		border-radius: 0 6px 6px 0;
		height: $inputHeight;
	}
}
