/* Class names prefixes */

$app-prefix: 'eb-' !default;
$nv-prefix: $app-prefix !default;
$rte-prefix: $app-prefix + 'rte-' !default;
$mdl-prefix: $app-prefix + 'mdl-' !default;
$am-prefix: $app-prefix + 'am-' !default;
$cm-prefix: $app-prefix + 'cm-' !default;
$pn-prefix: $app-prefix + 'pn-' !default;
$com-prefix: $app-prefix + 'com-' !default;
$sm-prefix: $app-prefix + 'sm-' !default;
$cv-prefix: $app-prefix + 'cv-' !default;
$clm-prefix: $app-prefix + 'clm-' !default;
$trt-prefix: $app-prefix + 'trt-' !default;
$cui-cls: $app-prefix + 'cui' !default;

/*
  New Pattern Color System
  With this should be easier to overwrite colors
  not only in SCSS but even CSS
*/
$primaryColor: #f35625 !default;
$secondaryColor: #1f1451 !default;
$tertiaryColor: #f4f7fc !default;
$quaternaryColor: #7e8299 !default;

/* Dark theme */
$mainColor: #444 !default; /* Light: #573454 Dark: #3b2639 -moz-linear-gradient(top, #fca99b 0%, #6e2842 100%) */
$fontColor: #ddd !default; /* l: #d8d7db */
$fontColorActive: #f8f8f8 !default;

$mainDkColor: rgba(
	0,
	0,
	0,
	0.2
) !default; /* darken($mainColor, 4%) - #383838 */
$mainDklColor: rgba(0, 0, 0, 0.1) !default;
$mainLhColor: rgba(255, 255, 255, 0.1) !default; /* #515151 */
$mainLhlColor: rgba(255, 255, 255, 0.7) !default;
$fontColorDk: #777 !default;
$colorBlue: #3b97e3 !default;
$colorRed: #dd3636 !default;
$colorYell: #ffca6f !default;
$colorGreen: #62c462 !default;
$tagBg: #804f7b !default;
$secColor: $tagBg !default;
$imageCompDim: 50px !default;
$leftWidth: 350px !default;

/* Color Helpers */
$colorHighlight: #71b7f1 !default;
$colorWarn: #ffca6f !default;

/* Canvas */
$hndlMargin: -5px !default;

/* Components / Inputs */
$lightBorder: rgba(255, 255, 255, 0.05) !default;
$inputFontColor: $mainLhlColor !default; /* #d5d5d5 */
$arrowColor: $mainLhlColor !default; /* b1b1b1 */
$darkTextShadow: $mainDkColor !default; /* #252525 */
$darkBorder: rgba(0, 0, 0, 0.15) !default; /* 303030 */
$colorpSize: 22px !default;
$inputPadding: 5px !default; // Has to be a single value

/* Class manager */
$addBtnBg: lighten($mainDkColor, 10%) !default;
$paddElClm: 5px 6px !default;

/* File uploader */
$uploadPadding: 150px 10px !default;

/* Commands */
$animSpeed: 0.2s !default;

/* Fonts */
$mainFont: Helvetica, sans-serif !default;
$fontSize: 0.7rem !default;
$fontSizeS: 0.75rem !default;

/* Tools */
$placeholderColor: $colorGreen !default;

/* Scrollbar */
$scrollbarWidth: 8px !default;

/* Z Indexes */
$z-dropzone: 11;
$z-preview: 1;
$z-preview-bg: 0;
$z-canvas: 1;
$z-ghost: 10;
$z-tools: 1;
$z-toolbar: 10;
$z-resizer-c: 9;
$z-sel-arrow: 0;
$z-field-arrows: 10;
$z-field-color-picker: 10;
$z-layer-vis: 1;
$z-modal-container: 100;
$z-modal-dialog: 2;
$z-panel: 3;
$z-views-panel: 4;
$z-main-view-panel: 4;
$z-traits-view-panel: 4;
$z-rte-toolbar: 10;
$z-field-select: 1;
$z-bdrag: 10;
$z-drag-helper: 10;
$z-off-prv: 10;
$z-badge: 1;
$z-placeholder: 10;
