@mixin media-breakpoint($breakpoint) {
	@if $breakpoint == xsmall {
		@media (min-width: 0) {
			@content;
		}
	} @else if $breakpoint == small {
		@media (min-width: 400px) {
			@content;
		}
	} @else if $breakpoint == medium {
		@media (min-width: 600px) {
			@content;
		}
	} @else if $breakpoint == large {
		@media (min-width: 900px) {
			@content;
		}
	} @else if $breakpoint == xlarge {
		@media (min-width: 1200px) {
			@content;
		}
	} @else {
		@media (min-width: $breakpoint) {
			@content;
		}
	}
}
