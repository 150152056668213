$editableFieldClass: '.' + $classPrefix + 'editable-field';

#{$editableFieldClass} {
	$self: &;
	align-items: center;
	display: flex;
	gap: 12px;

	&__buttons {
		align-items: center;
		display: flex;
		gap: 8px;

		&--hidden {
			display: none;
		}
	}

	&__label {
		&--clickable {
			cursor: text;
		}
	}

	// States - Opened
	&--view-mode {
		#{$self}__buttons {
			display: none;
		}

		&:hover {
			#{$self}__buttons:not(#{$self}__buttons--hidden) {
				display: flex;
			}
		}
	}
}
