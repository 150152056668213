$dropdownClass: '.' + $classPrefix + 'expand-collapse';

#{$dropdownClass} {
	--expandable-gap: 4px;

	$self: &;
	position: relative;

	&__expandable {
		left: 0;
		position: absolute;
		top: calc(100% + var(--expandable-gap));
	}

	&:not(&--expanded) {
		#{$self}__expandable {
			display: none;
		}
	}

	&--right {
		#{$self}__expandable {
			left: unset;
			right: 0;
		}
	}
}
