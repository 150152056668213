$toggleableSectionClass: '.' + $classPrefix + 'choosable-section';

#{$toggleableSectionClass} {
	$self: &;

	&__label-wrapper {
		align-items: center;
		display: flex;
		gap: 12px;
		justify-content: space-between;
	}
}
