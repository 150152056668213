//
//Z-indexes
//

//Dropdown
$z-index-dropdown: 10;
//Floater
$z-index-floater: 30;

//
// Components
//

$classPrefix: 'eb-' !default;

//
//Icons
//

//Sizes
$icon-sizes: (
	// 14px doc font-size
	xs: 0.5rem,
	//7px
	sm: 0.65rem,
	//9.1px
	default: 1rem,
	//14px
	md: 1.14rem,
	//16px
	ml: 1.3rem,
	//18.2px
	lg: 1.4rem,
	//19.6px
	xl: 1.7rem,
	//23.8px
	xxl: 2.86rem //40px
);

//Class Name
$icon-class: #{$classPrefix}i !default;

//Components dimensions
$inputHeight: 40px !default;
