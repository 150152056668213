$inputNumberClass: '.' + $classPrefix + 'input-number';

#{$inputNumberClass} {
	position: relative;

	&__input {
		padding-right: 32px;
		width: 100%;
	}

	&__upArrow,
	&__downArrow {
		color: #b5b4c3;
		cursor: pointer;
		font-size: get($icon-sizes, sm);
		position: absolute;
		right: 14px;
		top: 50%;
		z-index: 1; // To override gjs styles TODO: delete
	}

	&__upArrow {
		transform: translateY(-100%);
	}

	&__arrow {
		&--disabled {
			color: #e6e6e6;
			pointer-events: none;
		}
	}
}
