$tabsClass: '.' + $classPrefix + 'tabs';

#{$tabsClass} {
	&__tabs {
		align-items: center;
		border-bottom: 1px solid #eaedf2;
		display: flex;
		justify-content: space-around;
	}

	&__tab-item {
		cursor: pointer;
		font-size: 12px;
		font-weight: 500;
		padding: 8px 24px;
		text-transform: uppercase;

		&--active {
			color: #f35625;
			font-weight: 600;
			position: relative;

			&::after {
				background-color: currentColor;
				content: '';
				height: 2px;
				left: 0;
				position: absolute;
				top: calc(100% - 0.5px);
				width: 100%;
			}
		}
	}

	&__tab-pane {
		display: none;

		&--active {
			display: block;
		}
	}
}
