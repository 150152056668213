////
/// abstracts
////
@import './abstracts/functions/functions';
@import './abstracts/variables';
@import './abstracts/colors';
@import './abstracts/mixins';

////
/// base
////
@import './base/iconography';
@import './base/accessibility';

////
/// elements
////
@import './elements/elements';

////
/// components
////
@import './components/components';

////
/// vendors
////
@import './vendors/grapesjs/grapesjs.scss';
@import './vendors/richEditor';

.trt-header {
	//TODO: remove after on component:deselect event is fixed
	display: none;
}

////
/// Tailwind
////
@tailwind utilities;
