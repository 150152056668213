$sectionClass: '.' + $classPrefix + 'section';

#{$sectionClass} {
	&:where(& + &, &-item + &) {
		margin-top: 24px;
	}

	&__label {
		color: #7e8299;
		font-size: 14px;
		font-weight: 500;
	}

	&__content:where(&__label + &__content) {
		margin-top: 16px;
	}

	// TODO: Specify this in a layout stylesheet
	&-item:where(&-item + &-item) {
		margin-top: 12px;
	}
}
