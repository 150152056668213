$alertClass: '.' + $classPrefix + 'alert';
#{$alertClass} {
	padding: 15px;
	border: 1px solid transparent;
	border-radius: 4px;
	align-items: center;
	display: flex;
	gap: 10px;

	&--success {
		background-color: #fafffa;
		border-color: #7ed286;
	}

	&--info {
		background-color: #e9effd;
		border-color: #a5c2f0;
	}

	&--danger {
		background-color: #feeeec;
		border-color: #f95540;
	}

	&--warning {
		background-color: #fffaf1;
		border-color: #ffcd70;
	}

	&--hidden {
		display: none;
	}
}
