.ProseMirror {
	&-menubar-wrapper {
		border: 1px solid #eaedf2;
		border-radius: 6px;
	}

	&-menubar {
		align-items: center;
		border-bottom: 1px solid #eaedf2;
		display: flex;
		flex-wrap: wrap;
		gap: 8px 4px;
		padding: 8px;
	}

	&-menuseparator {
		background-color: #eaedf2;
		border-right: none;
		height: 20px;
		margin-right: 0;
		width: 2px;
	}

	&-menuitem {
		margin-right: 0;
	}

	&-menu-dropdown-wrap {
		padding: 0;
	}

	&-menu-dropdown {
		align-items: center;
		border: 1px solid #eaedf2;
		border-radius: 6px;
		display: flex;
		font-size: 12px;
		font-weight: 500;
		height: 25px;
		line-height: 25px;
		padding: 2px;
		padding-right: 24px;
		vertical-align: unset;
	}

	&-menu-dropdown-menu {
		background: white;
		border: 1px solid #eaedf2;
		border-radius: 6px;
		color: inherit;
		padding: 0;
		position: absolute;
		top: calc(100% + 2px);
	}

	&-menu-dropdown-item:has(&-menu-submenu-wrap) {
		padding-right: 0;
	}

	&-menu-submenu-wrap {
		margin-right: 0;
		padding-right: 16px;
	}

	&-menu-submenu {
		border: 1px solid #eaedf2;
		border-radius: 6px;
	}

	&-icon {
		align-items: center;
		aspect-ratio: 1;
		border: 1px solid #eaedf2;
		border-radius: 4px;
		display: inline-flex;
		justify-content: center;
		padding: 0;
		width: 25px;
	}
}
