$toggleableSectionClass: '.' + $classPrefix + 'toggleable-section';

#{$toggleableSectionClass} {
	$self: &;

	// TODO: Specify this in a layout stylesheet
	&:where(:not(:first-child)) {
		margin-top: 12px;
	}

	&__label-wrapper {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	&__content {
		margin-top: 6px;

		&:not(#{$self}--open > *) {
			display: none;
		}
	}

	&__toggle-label-wrapper {
		align-items: center;
		display: inline-flex;
		gap: 6px;
		justify-content: flex-end;
	}

	&__toggle-label {
		font-weight: unset;
	}
}
