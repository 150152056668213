/********* Input style **********/

@mixin rangeThumbStyle() {
	height: 10px;
	width: 10px;
	border: 1px solid $mainDkColor;
	border-radius: 100%;
	background-color: $fontColor;
	cursor: pointer;
}

@mixin rangeTrackStyle() {
	background-color: $mainDkColor;
	border-radius: 1px;
	margin-top: 3px;
	height: 3px;
}

.#{$app-prefix} {
	&label {
		line-height: 18px;
	}

	&fields {
		display: flex;
	}

	&select {
		padding: 0;
		width: 100%;

		select {
			padding-right: 10px;
		}
	}

	&select:-moz-focusring,
	&select select:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 $mainLhlColor;
	}

	&input:focus,
	&button:focus,
	&btn-prim:focus,
	&select:focus,
	&select select:focus {
		outline: none;
	}

	&field {
		&-range {
			flex: 9 1 auto;
		}

		&-integer {
			input {
				padding-right: 30px;
			}
		}
	}
}

.#{$app-prefix}select option,
.#{$app-prefix}field-select option,
.#{$clm-prefix}select option,
.#{$sm-prefix}select option,
.#{$app-prefix}fields option,
.#{$sm-prefix}unit option {
	background-color: $mainColor;
	color: $fontColor;
}

.#{$app-prefix}field {
	border: none;
	box-shadow: none;
	border-radius: 2px;
	box-sizing: border-box;
	padding: 0;
	position: relative;

	textarea {
		resize: vertical;
	}

	.#{$app-prefix}sel-arrow {
		height: 100%;
		width: 9px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: $z-sel-arrow;
	}

	.#{$app-prefix}d-s-arrow {
		bottom: 0;
		top: 0;
		margin: auto;
		right: $inputPadding;
		border-top: 4px solid $arrowColor;
		position: absolute;
		height: 0;
		width: 0;
		border-left: 3px solid transparent;
		border-right: 4px solid transparent;
		cursor: pointer;
	}

	&-arrows {
		position: absolute;
		cursor: ns-resize;
		margin: auto;
		height: 20px;
		width: 9px;
		z-index: $z-field-arrows;
		bottom: 0;
		right: $inputPadding - 2px;
		top: 0;
	}

	&-color,
	&-radio {
		width: 100%;
	}
}

.#{$app-prefix}field-color {
	input {
		padding-right: $colorpSize;
		box-sizing: border-box;
	}
}

.#{$app-prefix}field-colorp {
	border-left: 1px solid $mainDkColor;
	box-sizing: border-box;
	height: 100%;
	padding: 2px;
	position: absolute;
	right: 0;
	top: 0;
	width: $colorpSize;
	z-index: $z-field-color-picker;

	.#{$app-prefix}checker-bg {
		height: 100%;
		width: 100%;
		border-radius: 1px;
	}
}

.#{$app-prefix}field-colorp-c {
	@extend .#{$app-prefix}checker-bg;

	height: 100%;
	position: relative;
	width: 100%;
}

.#{$app-prefix}field-color-picker {
	background-color: $fontColor;
	cursor: pointer;
	height: 100%;
	width: 100%;
	box-shadow: 0 0 1px $mainDkColor;
	border-radius: 1px;
	position: absolute;
	top: 0;
}

/* ??? */
.#{$app-prefix}field-checkbox {
	padding: 0;
	width: 17px;
	height: 17px;
	display: block;
	cursor: pointer;

	input {
		display: none;
	}

	input:checked + .#{$app-prefix}chk-icon {
		border-color: rgba(255, 255, 255, 0.5);
		border-width: 0 2px 2px 0;
		border-style: solid;
	}
}

.#{$app-prefix}radio-item {
	flex: 1 1 auto;
	text-align: center;
	border-left: 1px solid $darkTextShadow;

	&:first-child {
		border: none;
	}

	&:hover {
		background: $mainDkColor;
	}

	input {
		display: none;
	}

	input:checked + .#{$app-prefix}radio-item-label {
		background-color: rgba(255, 255, 255, 0.2);
	}

	&s {
		display: flex;
	}
}

.#{$app-prefix}radio-item-label {
	cursor: pointer;
	display: block;
	padding: $inputPadding;
}

.#{$app-prefix}field-units {
	position: absolute;
	margin: auto;
	right: 10px;
	bottom: 0;
	top: 0;
}

.#{$app-prefix}field-unit {
	position: absolute;
	right: 10px;
	top: 3px;
	font-size: 10px;
	color: $arrowColor;
	cursor: pointer;
}

.#{$app-prefix}input-unit {
	text-align: center;
}

.#{$app-prefix}field-arrow-u,
.#{$app-prefix}field-arrow-d {
	position: absolute;
	height: 0;
	width: 0;
	border-left: 3px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid $arrowColor;
	bottom: 4px;
	cursor: pointer;
}

.#{$app-prefix}field-arrow-u {
	border-bottom: 4px solid $arrowColor;
	border-top: none;
	top: 4px;
}

.#{$app-prefix}field-select {
	padding: 0;
}

.#{$app-prefix}field-range {
	background-color: transparent;
	border: none;
	box-shadow: none;
	padding: 0;

	input {
		margin: 0;
		height: 100%;

		&:focus {
			outline: none;
		}

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			margin-top: -4px;

			@include rangeThumbStyle();
		}

		&::-moz-range-thumb {
			@include rangeThumbStyle();
		}

		&::-ms-thumb {
			@include rangeThumbStyle();
		}

		/* -moz-range-progress */
		&::-moz-range-track {
			@include rangeTrackStyle();
		}

		&::-webkit-slider-runnable-track {
			@include rangeTrackStyle();
		}

		&::-ms-track {
			@include rangeTrackStyle();
		}
	}
}

.#{$app-prefix}btn {
	&-prim {
		color: inherit;
		background-color: $mainLhColor;
		border-radius: 2px;
		padding: 3px 6px;
		padding: $inputPadding;
		cursor: pointer;
		border: none;

		&:active {
			background-color: $mainLhColor;
		}
	}

	&--full {
		width: 100%;
	}
}

.#{$app-prefix}chk-icon {
	@include transform(rotate(45deg));

	box-sizing: border-box;
	display: block;
	height: 14px;
	margin: 0 5px;
	width: 6px;
}

.#{$app-prefix}add-trasp {
	background: none;
	border: none;
	color: $fontColor;
	cursor: pointer;
	font-size: 1em;
	border-radius: 2px;

	@include opacity(0.75);

	&:hover {
		@include opacity(1);
	}

	&:active {
		background-color: rgba(0, 0, 0, 0.2);
	}
}
