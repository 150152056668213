.#{$app-prefix} {
	&traits-label {
		display: none;
	}

	&label {
		&-wrp {
			width: 30%;
			min-width: 30%;
		}
	}

	&field {
		&-wrp {
			flex-grow: 1;
		}
	}
}

.#{$trt-prefix}traits {
	&--single-trait {
		padding: 16px 10px;
	}
}

.#{$trt-prefix}header {
	font-weight: lighter;
	padding: 10px;
}

.#{$trt-prefix}trait {
	.#{$app-prefix}label {
		text-align: left;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
