$pillClass: '.' + $classPrefix + 'pill';
#{$pillClass} {
	$self: &;
	background-color: #f2f6fc;
	border-radius: 11px;
	border: 1px solid #b5b4c3;
	color: #3f4254;
	display: inline-block;
	font-size: 10px;
	font-weight: 500;
	line-height: 1.4;
	padding: 4px 8px;
	white-space: nowrap;
	height: 22px;
}
