////
/// The base color variables
/// @group Base colors
/// @type Color
////

/// White color hex code
$white: #ffffff;
/// Blue color hex code
$blue: #456cc0 !default;
/// Secondary color hex code
$secondary: #1f1451 !default;
/// Primary color hex code
$primary: #f35625;
/// Primary Light color hex code
$primary-light: #fdddd3;
/// Danger color hex code
$danger: #d1193a;
/// DT Gray 02 color hex code
$dt-gray-02: #eaedf2;
/// DT Gray 03 color hex code
$dt-gray-03: #b5b4c3;
