$imgInputClass: '.' + $classPrefix + 'img-input';

#{$imgInputClass} {
	$self: &;
	background: #f4f7fc;
	border: 1px dashed #b5b4c3;
	border-radius: 8px;
	color: #3f4254;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 12px;
	align-items: center;
	padding: 20px 16px;
	position: relative;
	text-align: center;

	&__placeholder {
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 14px;
	}

	&__icon {
		color: #b5b4c3;
		opacity: 0.5;
	}

	&__btn {
		// TODO: use extend
		background-color: transparent;
		border: 1px solid currentColor;
		border-radius: 6px;
		color: $primary;
		font-weight: 600;
		min-height: $inputHeight;
		padding: 10px 14px;
	}

	&__input:is(input) {
		cursor: pointer;
		height: 100%;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&__img {
		width: 100%;
	}

	&__overlay {
		background-color: #3f4254;
		display: none;
		height: 100%;
		left: 0;
		opacity: 0.3;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&__actions {
		align-items: center;
		background-color: #ffffff;
		border-radius: 6px;
		cursor: pointer;
		display: none;
		left: 50%;
		overflow: hidden;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&__action {
		padding: 8px 10px;
		position: relative;

		&:where(:not(:last-child))::before {
			background-color: #b5b4c3;
			content: '';
			display: inline-block;
			height: 17px;
			position: absolute;
			left: 100%;
			width: 1px;
		}

		&:hover {
			background: #f4f7fc;
		}
	}

	&--preview {
		border: none;
		cursor: default;
		overflow: hidden;
		padding: 0;

		#{$self}__input {
			display: none;
		}
	}

	&:hover {
		#{$self}__actions {
			display: inline-flex;
		}

		#{$self}__overlay {
			display: block;
		}
	}
}
